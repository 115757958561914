<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "xuNiJi",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "下载虚拟机",
                        text:[
                            '#复制链接"https://www.123pan.com/s/nmyojv-Ebl3A.html"下载虚拟机',
                            '#下载完成后,解压文件,目录如图所示',
                        ],
                        img:[
                            "1-1.png",
                        ]
                    },
                    {
                        title: "安装虚拟机",
                        text:[
                            '#按图片顺序安装虚拟机',
                        ],
                        img:[
                            "1.png",
                            "2.png",
                            "3.png",
                            "4.png",
                            "5.png",
                            "6.png",
                            "7.png",
                            "8.png",
                            "9.png",
                            "10.png",
                            "11.png",
                            "12.png",
                        ]
                    },
                    {
                        title: "替换文件",
                        text:[
                            '#找到刚刚安装虚拟机的位置,并打开目录下X64的文件夹打开(图1)',
                            '#找解压好的虚拟机目录,打开"2替换文件",把里面的两个文件复制并粘贴到上面的"X64文件夹"里替换文件(图234)',
                        ],
                        img:[
                            "13.png",
                            "14.png",
                            "15.png",
                            "16.png",
                        ]
                    },
                    {
                        title: "解压系统",
                        text:[
                            '#找解压好的虚拟机目录,打开"3win7",解压到任意硬盘下(剩余空间最好大于200G)',
                        ],
                        img:[
                            "17.png",
                            "18.png",
                        ]
                    },
                    {
                        title: "运行系统",
                        text:[
                            '#在桌面找到虚拟机图标, 并运行虚拟机(图1)',
                            '#打开后,点中间的打开虚拟机(图2)',
                            '#选择刚刚解压的"win7",并打开系统(图34)',
                            '#开启虚拟机(图56)',
                        ],
                        img:[
                            "19.png",
                            "20.png",
                            "21.png",
                            "22.png",
                            "23.png",
                            "24.png",
                        ]
                    },
                    {
                        title: "拷贝游戏",
                        text:[
                            '#在虚拟机打开"使用的工具"文件夹,运行复制工具(图1), 在自己电脑找到解压好的虚拟机目录,也运行复制工具(图2)',
                            '#找到九阴的文件夹,复制文件夹(图3)',
                            '#回到虚拟机,在虚拟机我的电脑C盘下鼠标右键粘贴(图4)',
                            '#等待完成,游戏既拷贝成功(图5)',
                            '#暂时不要运行游戏',
                        ],
                        img:[
                            "25.png",
                            "27.png",
                            "26.png",
                            "28.png",
                        ]
                    },
                    {
                        title: "安装过检测驱动",
                        text:[
                            '#在虚拟机打开"使用的工具"文件夹,打开重要驱动ST,右键管理员运行"64位驱动加载"(图12)',
                            '#点击箭头指向的点点点(图3), 打开此目录下的"过SP"文件夹,打开"killsky.sys"文件(图4)',
                            '#点击"安装"-"启动"-"关闭(图5)',
                            '#此时驱动安装完成,九阴不会检测到游戏在虚拟机运行,其他的驱动是过其他游戏检测的可以忽略',
                        ],
                        img:[
                            "32.jpg",
                            "33.jpg",
                            "34-1.png",
                            "34.jpg",
                            "35.jpg",
                        ]
                    },
                    {
                        title: "设置驱动开机启动",
                        text:[
                            '#需要设置驱动开机启动,否则每次开机都需要按上一步操作一遍',
                            '#按win+R键,启动运行,输入"regedit"打开注册表(图1)',
                            '#依次按图片选择路径,到services(图2)',
                            '#在services里找到"killsky",鼠标左键点击,看右侧"start",双击后,修改值从"3"修改"1",点击确定(图3)',
                            '#开机启动设置完成',
                        ],
                        img:[
                            "38.png",
                            "37.png",
                            "36.png",
                        ]
                    },
                    {
                        title: "运行助手支持库",
                        text:[
                            '#打开浏览器,下载好PC助手并解压',
                            '#右键管理员运行目录下的"支持库"文件(图1)',
                            '#开始游戏,运行助手(图23)',
                        ],
                        img:[
                            "30.png",
                            "29.png",
                            "31.png",
                        ]
                    },
                    {
                        title: "注意事项",
                        text:[
                            '#如果出现图1的提示,是没有"安装过检测驱动"或者是"设置驱动开机启动",重新设置这两步(图1)',
                            '#如果游戏镜头太快,点击虚拟机上面选项"编辑"-首选项(图2), 点击输入-为游戏优化鼠标,选择始终,即可解决问题(图3)',
                        ],
                        img:[
                            "39.jpg",
                            "40.png",
                            "41.png",
                        ]
                    },
                    {
                        title: "推荐视频",
                        text:[
                            '#如果想自己创建虚拟机,可以参考B站的视频' +
                            '"https://www.bilibili.com/video/BV1qw4116797/?vd_source=87448c93051178164f19686c9236e1b4"',
                            '#其中软件,系统需要自行下载',
                        ],
                        img:[]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
